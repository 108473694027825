import React from "react"
import { graphql } from "gatsby"

import { Contact as Page } from "../components/Contact/ContactB2B"

export const query = graphql`
  query {
    page: sanityPageContactB2B {
      title
      hideTitle
      header: _rawHeader(resolveReferences: { maxDepth: 10 })
      links: _rawLinks(resolveReferences: { maxDepth: 2 })
      faqs: _rawFaqs(resolveReferences: { maxDepth: 8 })
      contactFormDescription
      contacts: _rawContacts(resolveReferences: { maxDepth: 2 })
      additionalLinksText
      additionalFaqsText
      additionalEnquiresText
      additionalLocationsText
      footer: _rawFooter(resolveReferences: { maxDepth: 10 })
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
